
import { Vue, Options } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import debounce from "lodash/debounce";
import { STTPaidUnpaidController } from "@/app/ui/controllers/STTPaidUnpaidController";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import formatDateWithDay from "@/app/infrastructures/misc/common-library/FormatDateWithDay";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { GTMSttPaidUnpaidEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/stt-paid-unpaid-events";
import { RequestSttPaidUnread } from "@/domain/entities/STTPaidUnpaid";
import { ShipmentBooking } from "@/domain/entities/ShipmentBooking";

@Options({
  components: {
    EmptyState
  }
})
export default class SttPaidUnpaid extends Vue {
  openTimestamp = 0;
  mounted() {
    STTPaidUnpaidController.getTotalSTTPaidUnread({
      action: "component",
      payload: new RequestSttPaidUnread({ cache: true })
    });
    this.openTimestamp = new Date().getTime();
  }

  beforeUnmount() {
    this.onResetState();
    STTPaidUnpaidController.setFirstRequest(true);
    GTMSttPaidUnpaidEvent("session_stt_paid/unpaid", {
      openTimestamp: this.openTimestamp,
      closeTimestamp: new Date().getTime()
    });
  }

  fetchSTTPaidUnpaid() {
    STTPaidUnpaidController.fetchSTTPaidUnpaid();
  }

  get firstRequest() {
    return STTPaidUnpaidController.firstRequest;
  }

  get STTPaidUnpaidData() {
    return STTPaidUnpaidController.shipmentBookingData.data;
  }

  get accountLocation() {
    return AccountController.accountData.account_type_detail?.partnerLocation
      ?.city_code;
  }

  setFirstPage() {
    STTPaidUnpaidController.setFirstPage();
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get isInternal() {
    return (
      this.dataProfile.account_type === "internal" ||
      this.dataProfile.isCustomerService
    );
  }

  //Filter Search
  async onSearch(value: string) {
    await STTPaidUnpaidController.setSearch(value);
    await STTPaidUnpaidController.setFirstPage();
    this.fetchSTTPaidUnpaid();
    GTMSttPaidUnpaidEvent("search_stt", { sttNumber: value }, ["timestamp"]);
  }
  get searchValue() {
    return STTPaidUnpaidController.search;
  }
  clearSearch() {
    STTPaidUnpaidController.setSearch("");
    this.fetchSTTPaidUnpaid();
  }

  // advance filter v2
  get advancedFilterData() {
    return STTPaidUnpaidController.filter;
  }

  // payment status
  paymentStatusOpen = false;
  selectedPaymentStatus: any = "";

  onSelectPaymentStatus(items: any) {
    if (this.advancedFilterData.paymentStatus?.value === items.value) {
      this.advancedFilterData.paymentStatus = { name: "", value: "" };
    } else {
      this.advancedFilterData.paymentStatus = items;
      this.advancedFilterData.processStatus = { name: "", value: "" };
    }
    this.selectedPaymentStatus = items.name;
    this.selectedProcessStatus = "";
    this.paymentStatusOpen = false;
    STTPaidUnpaidController.setFirstPage();
    this.fetchSTTPaidUnpaid();
    GTMSttPaidUnpaidEvent(
      "status_pembayaran",
      { pembayaran: this.selectedPaymentStatus },
      ["timestamp"]
    );
  }
  get paymentStatusOptions() {
    return [
      { name: "Kurang Bayar", value: "unpaid" },
      { name: "Sudah Lunas", value: "paid" },
      { name: "Pembayaran COD", value: "cod-payment" }
    ];
  }

  // process status
  processStatusOpen = false;
  selectedProcessStatus: any = "";

  onSelectProcessStatus(items: any) {
    if (this.advancedFilterData.processStatus.value === items.value) {
      this.advancedFilterData.processStatus = { name: "", value: "" };
    } else {
      this.advancedFilterData.processStatus = items;
      const paymentStatus: any = this.paymentStatusOptions.find(
        e => e.value === items.paymentStatus
      );
      this.selectedPaymentStatus = paymentStatus;
      this.advancedFilterData.paymentStatus = paymentStatus;
    }
    this.selectedProcessStatus = items.name;
    this.processStatusOpen = false;
    STTPaidUnpaidController.setFirstPage();
    this.fetchSTTPaidUnpaid();
    GTMSttPaidUnpaidEvent(
      "status_proses",
      {
        proses: this.selectedProcessStatus,
        statusPembayaran: this.advancedFilterData.paymentStatus?.value
      },
      ["timestamp"]
    );
  }

  get processStatusOptions() {
    const paid = [
      { name: "Belum Diproses", value: "unprocess", paymentStatus: "paid" },
      { name: "Sudah Diproses", value: "process", paymentStatus: "paid" }
    ];
    const unpaid = [
      {
        name: "Perlu Dibayar",
        value: "waiting-payment",
        paymentStatus: "unpaid"
      },
      {
        name: "Perlu Dibatalkan",
        value: "need-to-cancel",
        paymentStatus: "unpaid"
      }
    ];

    if (this.advancedFilterData.paymentStatus?.value === "paid") {
      return paid;
    }
    if (this.advancedFilterData.paymentStatus?.value === "unpaid") {
      return unpaid;
    }
    return [...paid, ...unpaid];
  }

  // Origin destination
  isChevron = false;
  fetchDestination = debounce((search: string) => {
    search = search?.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    if (!search.length || search.length >= 3) {
      LocationController.getCityList({
        page: 1,
        limit: 10,
        search: search,
        status: ""
      });
    }
  }, 500);
  get loadingDestination() {
    return LocationController.isLoading;
  }
  get destinationList() {
    const city = LocationController.cityData.cityData;
    return city.length
      ? city.map(item => ({ ...item, name: `${item.code} - ${item.name}` }))
      : [];
  }
  onChangeOrigin() {
    this.advancedFilterData.cityOrigin =
      this.advancedFilterData.cityOrigin || "";
    STTPaidUnpaidController.setFirstPage();
    this.fetchSTTPaidUnpaid();
  }
  onChangeDestination() {
    this.advancedFilterData.cityDestination =
      this.advancedFilterData.cityDestination || "";
    STTPaidUnpaidController.setFirstPage();
    this.fetchSTTPaidUnpaid();
    if (this.advancedFilterData.cityDestination?.name) {
      GTMSttPaidUnpaidEvent(
        "destination_city",
        {
          kotaTujuan: this.advancedFilterData.cityDestination?.name
        },
        ["timestamp"]
      );
    }
  }

  // datepicker
  setPeriode(value: Array<any>) {
    if (
      this.advancedFilterData.startDateRange !== value[0] &&
      !this.firstRequest
    ) {
      this.advancedFilterData.startDateRange = value[0];
      this.advancedFilterData.endDateRange = value[1];
      STTPaidUnpaidController.setFirstPage();
      if (value[0] && value[1]) {
        GTMSttPaidUnpaidEvent(
          "choose_date",
          {
            dateStart: formatDateWithDay(value[0]),
            dateEnd: formatDateWithDay(value[1])
          },
          ["timestamp"]
        );
      }
      value[0] && value[1] && this.fetchSTTPaidUnpaid();
    }
    STTPaidUnpaidController.setFirstRequest(false);
  }

  onResetState() {
    STTPaidUnpaidController.resetFilter();
    // reset date range
    const refs: any = this.$refs.dateRangeFilter;
    refs.dates = { start: null, end: null };
    refs.onDateSelected();
    STTPaidUnpaidController.setSearch("");
  }

  get isFilterEmpty() {
    return !(
      this.searchValue ||
      this.advancedFilterData.paymentStatus?.value ||
      this.advancedFilterData.processStatus.value ||
      this.advancedFilterData.cityOrigin?.code ||
      this.advancedFilterData.cityDestination?.code ||
      this.advancedFilterData.startDateRange ||
      this.advancedFilterData.endDateRange
    );
  }

  onResetFilter() {
    if (!this.isFilterEmpty) {
      this.onResetState();
      this.fetchSTTPaidUnpaid();
      GTMSttPaidUnpaidEvent(
        "reset_filter",
        {
          reset: "Y"
        },
        ["timestamp"]
      );
    }
  }

  isShowFilter = false;
  onToggleShowFilter(value: boolean) {
    this.isShowFilter = value;
    GTMSttPaidUnpaidEvent(
      "hide_filter",
      {
        hide: this.isShowFilter ? "N" : "Y"
      },
      ["timestamp"]
    );
  }

  onDownloadStt() {
    STTPaidUnpaidController.getDownloadStt();
    GTMSttPaidUnpaidEvent("download_stt_paid/unpaid", undefined, ["timestamp"]);
  }

  // Pagination Table
  get pagination() {
    return STTPaidUnpaidController.shipmentBookingData.pagination;
  }

  //Loading
  get isLoading() {
    return STTPaidUnpaidController.isLoading;
  }

  //Error
  get isError() {
    return STTPaidUnpaidController.isError;
  }

  //Error Cause
  get errorCause() {
    return STTPaidUnpaidController.isErrorCause;
  }

  // Table
  columns = [
    {
      name: "No. STT",
      styleHead: "w-40 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: ShipmentBooking) => {
        return `<div class='text-left text-red-lp-200'>${item.sttNo}</div>`;
      }
    },
    {
      name: "Status Terakhir",
      styleHead: "w-56 text-left",
      render: (item: ShipmentBooking) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded-full capitalize px-2 py-0 bg-gray-lp-400">
                      ${
                        item.sttLastStatusId?.toLowerCase() === "hnd"
                          ? "HANDOVER"
                          : item.sttLastStatusId
                      } (${item.sttCounter}/${item.sttTotalPiece})
                  </div>
                </div>`;
      },
      toolTip: (item: any) => {
        return `<div class='flex text-black-lp-300 text-12px max-w-xs'>${item.sttLastStatusIdDescription}</div>`;
      }
    },
    {
      name: "Tanggal Booking",
      styleHead: "w-64 text-left",
      render: (item: ShipmentBooking) => {
        return `<div class="text-black-lp-300">${formatDate(
          item.sttCreatedAt
        )}</div>`;
      }
    },
    {
      name: "Status Pembayaran",
      styleHead: "w-52 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: ShipmentBooking) => {
        let data = {
          label: "",
          style: ""
        };

        if (item.sttPaymentStatus !== "paid") {
          if (new RegExp(/^ARA|^ARB/).exec(item.sttShipmentId)) {
            data = {
              label: "Pembayaran COD",
              style: "bg-blue-lp-200 text-blue-lp-300"
            };
          } else {
            data = {
              label: "Kurang Bayar",
              style: "bg-yellow-lp-500 text-white"
            };
          }
        } else {
          data = {
            label: "Sudah Lunas",
            style: "bg-green-lp-700 text-white"
          };
        }

        return `<div class='text-left ${data.style} font-medium font-montserrat rounded-md px-3 py-1 whitespace-no-wrap text-12px xxl:text-14px'>${data.label}</div>`;
      }
    },
    {
      name: "Tanggal Pembayaran",
      styleHead: "w-64 text-left",
      render: (item: ShipmentBooking) => {
        return `<div class="text-black-lp-300">${
          item.sttPaymentDate ? formatDate(item.sttPaymentDate) : "-"
        }</div>`;
      }
    },
    {
      name: "Status Proses",
      styleHead: "w-40 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: ShipmentBooking) => {
        return `<div class='text-left ${this.convertLabel(
          item.sttProcessState?.toLowerCase()
        )} font-medium font-montserrat whitespace-no-wrap text-12px xxl:text-14px'>${this.processState(
          item.sttProcessState || ""
        )}</div>`;
      }
    },
    {
      name: "Kota Asal",
      styleHead: "w-52 text-left",
      render: (item: ShipmentBooking) => {
        return `<div class="text-black-lp-300">${item.sttOriginCity} - ${item.sttOriginCityName}</div>`;
      }
    },
    {
      name: "Kota Tujuan",
      styleHead: "w-52 text-left",
      render: (item: ShipmentBooking) => {
        return `<div class="text-black-lp-300">${item.sttDestinationCity} - ${item.sttDestinationCityName}</div>`;
      }
    },
    {
      name: "Tanggal Diubah",
      styleHead: "w-64 text-left",
      render: (item: ShipmentBooking) => {
        return `<div class="text-black-lp-300">${formatDate(
          item.sttUpdatedAt
        )}</div>
                <div class="capitalize text-gray-lp-500">${ellipsisString(
                  item.sttUpdateBy || "",
                  30
                )}</div>`;
      }
    }
  ];

  processState(item: string) {
    let state = "";
    switch (item.toLowerCase()) {
      case "sudah diproses":
        state = "Sudah Diproses";
        break;
      case "belum diproses":
        state = "Belum Diproses";
        break;
      case "perlu dibayar":
        state = "Perlu Dibayar";
        break;
      case "perlu dibatalkan":
        state = "Perlu Dibatalkan";
        break;
      default:
        state = "Sudah Diproses";
        break;
    }
    return state;
  }

  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }

  convertLabel(status: any) {
    const data: any = {
      "sudah diproses": "text-green-lp-400",
      "belum diproses": "text-yellow-lp-100",
      "perlu dibayar": "text-yellow-lp-100"
    };

    return data[status] ?? "text-red-lp-1200";
  }
}
