
import { prop, Vue } from "vue-class-component";
import { PropType } from "vue";

class Props {
  total = prop<number>({
    default: 0,
    type: Number
  });
  onCloseTotalNotification = prop<any>({
    default: Function as PropType<() => void>,
    type: Function
  });
}
export default class TotalNotification extends Vue.with(Props) {}
