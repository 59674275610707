
import { Vue, Options } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import debounce from "lodash/debounce";
import { STTPaidUnpaidController } from "@/app/ui/controllers/STTPaidUnpaidController";
import { formatDate, ellipsisString } from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import TotalNotification from "@/app/ui/views/shipment/stt-paid-unpaid/v1/component/total-notification.vue";
import { RequestSttPaidUnread } from "@/domain/entities/STTPaidUnpaid";

@Options({
  components: {
    EmptyState,
    TotalNotification
  }
})
export default class ShipmentBooking extends Vue {
  mounted() {
    STTPaidUnpaidController.getTotalSTTPaidUnread({
      action: "component",
      payload: new RequestSttPaidUnread({ cache: true }),
    });
  }

  beforeUnmount() {
    this.onCloseTotalNotification();
  }

  fetchSTTPaidUnpaid() {
    STTPaidUnpaidController.fetchSTTPaidUnpaid();
  }

  onRequest() {
    this.fetchSTTPaidUnpaid();
  }

  get firstRequest() {
    return STTPaidUnpaidController.firstRequest;
  }

  get STTPaidUnpaidData() {
    return STTPaidUnpaidController.shipmentBookingData.data;
  }

  //Filter Search
  async onSearch(value: string) {
    await STTPaidUnpaidController.setSearch(value);
    await STTPaidUnpaidController.setFirstPage();
    this.fetchSTTPaidUnpaid();
  }
  get searchValue() {
    return STTPaidUnpaidController.search;
  }
  clearSearch() {
    STTPaidUnpaidController.setSearch("");
    this.fetchSTTPaidUnpaid();
  }

  get accountLocation() {
    return AccountController.accountData.account_type_detail?.partnerLocation
      ?.city_code;
  }

  setFirstPage() {
    STTPaidUnpaidController.setFirstPage();
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get isInternal() {
    return this.dataProfile.account_type === "internal";
  }

  // advance filter
  isAdvancedFilterOpened = false;
  onChangeAdvancedFilter = debounce(event => {
    this.setFirstPage();
    STTPaidUnpaidController.setFilter(event);
    if (!this.firstRequest) this.onRequest();
  }, 500);
  onOpenedAdvancedFilter(event: any) {
    if (this.firstRequest) STTPaidUnpaidController.setFirstRequest(false);
    this.isAdvancedFilterOpened = event;
  }
  onResetAdvancedFilter = debounce(() => {
    STTPaidUnpaidController.resetFilter();
    this.onRequest();
  }, 500);
  get advancedFilterData() {
    return STTPaidUnpaidController.filter;
  }

  // Pagination Table
  get pagination() {
    return STTPaidUnpaidController.shipmentBookingData.pagination;
  }

  //Loading
  get isLoading() {
    return STTPaidUnpaidController.isLoading;
  }

  //Error
  get isError() {
    return STTPaidUnpaidController.isError;
  }

  //Error Cause
  get errorCause() {
    return STTPaidUnpaidController.isErrorCause;
  }

  // Table
  columns = [
    {
      name: "No. STT",
      styleHead: "w-36 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: any) => {
        return `<div class='text-left text-red-lp-200'>${item.sttNo}</div>`;
      }
    },
    {
      name: "Status Terakhir",
      styleHead: "w-48 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded-full capitalize px-2 py-0 bg-gray-lp-400">
                      ${
                        item.sttLastStatusId?.toLowerCase() === "hnd"
                          ? "HANDOVER"
                          : item.sttLastStatusId
                      } (${item.sttCounter}/${item.sttTotalPiece})
                  </div>
                </div>`;
      },
      toolTip: (item: any) => {
        return `<div class='flex text-black-lp-300 text-12px max-w-xs'>${item.sttLastStatusIdDescription}</div>`;
      }
    },
    {
      name: "Status Pembayaran",
      styleHead: "w-40 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<div class='text-left ${
          item.sttPaymentStatus === "paid"
            ? "bg-green-lp-700"
            : "bg-yellow-lp-500"
        } text-white font-medium font-montserrat rounded-md px-3 py-1 whitespace-no-wrap text-12px xxl:text-14px'>${
          item.sttPaymentStatus === "paid" ? "Sudah Lunas" : "Kurang Bayar"
        }</div>`;
      }
    },
    {
      name: "Tanggal Bayar",
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${
          item.sttPaymentDate ? formatDate(item.sttPaymentDate) : "-"
        }</div>`;
      }
    },
    {
      name: "Kota Asal",
      styleHead: "w-32 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${item.sttOriginCity} - ${item.sttOriginCityName}</div>`;
      }
    },
    {
      name: "Kota Tujuan",
      styleHead: "w-32 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${item.sttDestinationCity} - ${item.sttDestinationCityName}</div>`;
      }
    },
    {
      name: "Tanggal Diubah",
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${formatDate(
          item.sttUpdatedAt
        )}</div>
                <div class="capitalize text-gray-lp-500">${ellipsisString(
                  item.sttUpdateBy,
                  30
                )}</div>`;
      }
    }
  ];

  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }

  get totalSTTPaidUnread() {
    return STTPaidUnpaidController.totalSTTPaidUnreadData.totalUnread;
  }

  // close component total notification
  onCloseTotalNotification() {
    STTPaidUnpaidController.setTotalSTTPaidUnread({
      totalUnread: 0,
      lastOpenAt: ""
    });
  }
}
