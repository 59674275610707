import { Pagination } from "@/domain/entities/Pagination";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import { ShipmentBookingEntities } from "@/domain/entities/ShipmentBooking";
import { ShipmentBookingPresenter } from "../presenters/ShipmentBookingPresenter";
import { STTPaidUnpaidPresenter } from "../presenters/STTPaidUnpaidPresenter";
import { container } from "tsyringe";
import { RequestListBooking } from "@/data/payload/api/BookingRequest";
import { AccountController } from "./AccountController";
import {
  RequestSttPaidUnread,
  TotalSTTPaidUnread
} from "@/domain/entities/STTPaidUnpaid";
import { isUrlUpdateVersion } from "@/app/infrastructures/misc/Utils";
import { MainAppController } from "./MainAppController";
import { ReportController } from "./ReportController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface STTPaidUnpaidState {
  isLoading: boolean;
  isError: boolean;
  isErrorCause: string;
}
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "stt_paid_unpaid"
})
class STTPaidUnpaidStore extends VuexModule implements STTPaidUnpaidState {
  public isLoading = false;
  public isError = false;
  public isErrorCause = "";
  public search = "";
  public filter = {
    paymentStatus: { name: "", value: "" },
    processStatus: { name: "", value: "" },
    lastStatus: { name: "", value: "" },
    startDateRange: "",
    endDateRange: "",
    status: "",
    cityOrigin: "" as any,
    cityDestination: "" as any
  };
  public shipmentBookingData = new ShipmentBookingEntities(
    new Pagination(1, 20),
    []
  );
  public totalSTTPaidUnreadData = new TotalSTTPaidUnread();
  public firstRequest = false;

  @Mutation
  private setShipmentBookingList(data: ShipmentBookingEntities) {
    this.shipmentBookingData = data;
  }

  @Action
  public getShipmentBookingList(params: RequestListBooking) {
    this.setLoading(true);
    const presenter = container.resolve(ShipmentBookingPresenter);
    presenter
      .getBookingList(params)
      .then((res: ShipmentBookingEntities) => {
        this.setShipmentBookingList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async fetchSTTPaidUnpaid() {
    await this.setFirstRequest(false);
    this.getShipmentBookingList(
      new RequestListBooking({
        cache: true,
        search: this.search,
        page: this.shipmentBookingData.pagination.page,
        isTotalData: true,
        limit: this.shipmentBookingData.pagination.limit,
        originCityId:
          AccountController.accountData.account_type === "internal" ||
          AccountController.accountData.isCustomerService
            ? this.filter.cityOrigin?.code
            : AccountController.accountData.account_type_detail?.partnerLocation
                ?.city_code,
        destinationCityId: this.filter.cityDestination?.code,
        paymentStatus: this.filter.paymentStatus?.value,
        version: isUrlUpdateVersion("paid-unpaid") === "v2" ? "" : "2",
        orderBy:
          isUrlUpdateVersion("paid-unpaid") === "v2"
            ? "stt_updated_at"
            : this.filter.paymentStatus.value === "unpaid"
            ? "stt_updated_at"
            : "stt_payment_date_at",
        sortBy: "desc",
        from: isUrlUpdateVersion("paid-unpaid") === "v2" ? "payment" : "",
        processState:
          isUrlUpdateVersion("paid-unpaid") === "v2"
            ? this.filter.processStatus.value
            : "",
        startUpdated: this.startUpdated,
        endUpdated: this.endUpdated,
        minPayDate: this.minDate,
        maxPayDate: this.maxDate
      })
    );
  }

  @Action
  public async initSTTPaidUnpaid() {
    await this.setFirstPage();
    await this.setFirstRequest(true);
    await this.setSearch("");
    await this.resetFilter();
    this.setFilter({
      ...this.filter,
      paymentStatus: { name: "", value: "" },
      startDateRange: new Date(
        new Date(new Date().setDate(new Date().getDate() - 6))
      ),
      endDateRange: new Date(new Date(new Date().setDate(new Date().getDate())))
    });
    this.getShipmentBookingList(
      new RequestListBooking({
        cache: true,
        search: this.search,
        page: this.shipmentBookingData.pagination.page,
        isTotalData: true,
        limit: this.shipmentBookingData.pagination.limit,
        originCityId:
          AccountController.accountData.account_type === "internal" ||
          AccountController.accountData.isCustomerService
            ? this.filter.cityOrigin?.code
            : AccountController.accountData.account_type_detail?.partnerLocation
                ?.city_code,
        destinationCityId: this.filter.cityDestination?.code,
        paymentStatus: this.filter.paymentStatus.value,
        version: isUrlUpdateVersion("paid-unpaid") === "v2" ? "" : "2",
        orderBy:
          isUrlUpdateVersion("paid-unpaid") === "v2"
            ? "stt_updated_at"
            : this.filter.paymentStatus.value === "unpaid"
            ? "stt_updated_at"
            : "stt_payment_date_at",
        sortBy: "desc",
        from: isUrlUpdateVersion("paid-unpaid") === "v2" ? "payment" : "",
        processState:
          isUrlUpdateVersion("paid-unpaid") === "v2"
            ? this.filter.processStatus.value
            : "",
        startUpdated: this.startUpdated,
        endUpdated: this.endUpdated,
        minPayDate: this.minDate,
        maxPayDate: this.maxDate
      })
    );
  }

  get minDate() {
    if (isUrlUpdateVersion("paid-unpaid") === "v2") {
      return "";
    }
    return this.filter.startDateRange
      ? new Date(this.filter.startDateRange).toLocaleDateString("fr-CA")
      : "";
  }

  get maxDate() {
    if (isUrlUpdateVersion("paid-unpaid") === "v2") {
      return "";
    }
    return this.filter.endDateRange
      ? new Date(this.filter.endDateRange).toLocaleDateString("fr-CA")
      : "";
  }

  get startUpdated() {
    if (isUrlUpdateVersion("paid-unpaid") === "v2") {
      return this.filter.startDateRange
        ? new Date(this.filter.startDateRange).toLocaleDateString("fr-CA")
        : "";
    }
    return "";
  }

  get endUpdated() {
    if (isUrlUpdateVersion("paid-unpaid") === "v2") {
      return this.filter.endDateRange
        ? new Date(this.filter.endDateRange).toLocaleDateString("fr-CA")
        : "";
    }
    return "";
  }

  @Action
  public async resetFilter() {
    this.setFilter({
      paymentStatus: { name: "", value: "" },
      processStatus: { name: "", value: "" },
      lastStatus: { name: "", value: "" },
      startDateRange: "",
      endDateRange: "",
      status: "",
      cityOrigin:
        AccountController.accountData.account_type === "internal" ||
        AccountController.accountData.isCustomerService
          ? ""
          : AccountController.accountData.account_type_detail?.partnerLocation
              ?.city_code,
      cityDestination: "" as any
    });
  }

  @Action
  public async getDownloadStt() {
    MainAppController.showLoading();
    const presenter = container.resolve(STTPaidUnpaidPresenter);
    return presenter
      .getDownloadStt(
        new RequestListBooking({
          search: this.search,
          page: this.shipmentBookingData.pagination.page,
          isTotalData: true,
          limit: this.shipmentBookingData.pagination.limit,
          originCityId: this.filter.cityOrigin?.code,
          destinationCityId: this.filter.cityDestination?.code,
          paymentStatus: this.filter.paymentStatus.value,
          version: isUrlUpdateVersion("paid-unpaid") === "v2" ? "" : "2",
          orderBy:
            isUrlUpdateVersion("paid-unpaid") === "v2"
              ? "stt_updated_at"
              : this.filter.paymentStatus.value === "unpaid"
              ? "stt_updated_at"
              : "stt_payment_date_at",
          sortBy: "desc",
          from: isUrlUpdateVersion("paid-unpaid") === "v2" ? "payment" : "",
          processState:
            isUrlUpdateVersion("paid-unpaid") === "v2"
              ? this.filter.processStatus.value
              : "",
          startUpdated: this.startUpdated,
          endUpdated: this.endUpdated,
          minPayDate: this.minDate,
          maxPayDate: this.maxDate
        })
      )
      .then(() => {
        ReportController.onGetListDownloadReport();
        ReportController.setOpenDownloadRequest(true);
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Download STT Gagal!", () => {
            this.getDownloadStt();
            MainAppController.closeErrorMessage();
          })
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Mutation
  public setTotalSTTPaidUnread(data: TotalSTTPaidUnread) {
    this.totalSTTPaidUnreadData = data;
  }

  @Action
  public getTotalSTTPaidUnread(params: {
    action: string;
    payload: RequestSttPaidUnread;
  }) {
    const presenter = container.resolve(STTPaidUnpaidPresenter);
    presenter
      .getTotalSTTPaidUnread(params.payload)
      .then((res: TotalSTTPaidUnread) => {
        this.setTotalSTTPaidUnread(res);
        if (params.action === "component") {
          this.setLastOpenTotalSTTPaidUnread();
        }
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      });
  }

  @Action
  public setLastOpenTotalSTTPaidUnread() {
    const presenter = container.resolve(STTPaidUnpaidPresenter);
    presenter
      .setLastOpenTotalSTTPaidUnread()
      .then(() => {
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      });
  }

  @Mutation
  public setFilter(value: any) {
    this.filter = value;
    if (isUrlUpdateVersion("paid-unpaid") === "v1") {
      this.filter.cityOrigin = { code: value.cityOriginCode };
      this.filter.cityDestination = { code: value.cityDestinationCode };
    }
  }

  @Action
  public async setFirstPage() {
    this.shipmentBookingData.pagination.page = 1;
  }

  @Mutation
  public async setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Mutation
  public setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  public setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  public setErrorCause(val: string) {
    this.isErrorCause = val;
  }

  @Mutation
  public async setSearch(val: string) {
    this.search = val;
  }
}
export const STTPaidUnpaidController = getModule(STTPaidUnpaidStore);
