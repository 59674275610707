import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "\n      pt-0\n      bg-white\n      flex flex-col\n      text-12px\n      xxl:text-14px\n      overflow-hidden\n      h-screen\n      relative\n      w-full\n    " }
const _hoisted_2 = { class: "flex gap-x-4" }
const _hoisted_3 = { class: "\n            font-semibold\n            text-20px\n            whitespace-no-wrap\n            text-black-lp-200\n            capitalize\n          " }
const _hoisted_4 = {
  key: 2,
  class: "relative flex justify-center"
}
const _hoisted_5 = { class: "overflow-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_advanced_filter = _resolveComponent("advanced-filter")!
  const _component_Header = _resolveComponent("Header")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TotalNotification = _resolveComponent("TotalNotification")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode("div", {
      class: "fixed right-0 h-full z-20",
      style: 
        `background-color:rgba(0,0,0,0.3); top: 10.4rem; width: calc(100% - ${
          _ctx.isAdvancedFilterOpened && _ctx.isCollapseSidebar ? `60px` : `270px`
        });`
      
    }, null, 4), [
      [_vShow, _ctx.isAdvancedFilterOpened]
    ]),
    _createVNode(_component_Header, { class: "items-center" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, _toDisplayString(_ctx.$route.meta.name), 1),
          _createVNode(_component_Search, {
            onInput: _ctx.onSearch,
            onClear: _ctx.clearSearch,
            value: _ctx.searchValue,
            class: "w-250px",
            placeholder: "Cari No. STT"
          }, null, 8, ["onInput", "onClear", "value"]),
          _createVNode(_component_advanced_filter, {
            "model-value": _ctx.advancedFilterData,
            isCityOrigin: _ctx.isInternal,
            isCityDestination: "",
            isDisabled: false,
            isStatusPayment: "",
            isDateRange: "",
            titleDateRange: "Tanggal Bayar",
            defaultDateRange: "7 hari terakhir",
            onChange: _ctx.onChangeAdvancedFilter,
            onOpened: _ctx.onOpenedAdvancedFilter,
            onReset: _ctx.onResetAdvancedFilter
          }, null, 8, ["model-value", "isCityOrigin", "onChange", "onOpened", "onReset"])
        ])
      ]),
      _: 1
    }),
    (_ctx.isError && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          errorType: _ctx.errorCause,
          onTryAgain: _ctx.onRequest
        }, null, 8, ["errorType", "onTryAgain"]))
      : (_ctx.STTPaidUnpaidData.length === 0 && _ctx.firstRequest && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 1,
            data: "data STT Paid/Unpaid"
          }))
        : (_openBlock(), _createBlock("div", _hoisted_4, [
            _withDirectives(_createVNode(_component_TotalNotification, {
              onCloseTotalNotification: _ctx.onCloseTotalNotification,
              total: _ctx.totalSTTPaidUnread
            }, null, 8, ["onCloseTotalNotification", "total"]), [
              [_vShow, _ctx.totalSTTPaidUnread]
            ]),
            _createVNode("div", _hoisted_5, [
              _createVNode(_component_TableV2, {
                class: "my-2",
                columns: _ctx.columns,
                data: _ctx.STTPaidUnpaidData,
                loading: _ctx.isLoading,
                pagination: _ctx.pagination,
                "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
                onRequest: _ctx.onRequest,
                paginationStyle: "v3",
                isPinnedScrollBottom: ""
              }, null, 8, ["columns", "data", "loading", "pagination", "onRequest"])
            ])
          ]))
  ]))
}