
import { isUrlUpdateVersion } from "@/app/infrastructures/misc/Utils";
import { STTPaidUnpaidController } from "@/app/ui/controllers/STTPaidUnpaidController";
import { Vue, Options } from "vue-class-component";
import STTPaidUnpaidV1 from "./v1/index.vue";
import STTPaidUnpaidV2 from "./v2/index.vue";

@Options({
  components: {
    STTPaidUnpaidV1,
    STTPaidUnpaidV2
  }
})
export default class STTPaidUnpaidDashboard extends Vue {
  mounted() {
    STTPaidUnpaidController.initSTTPaidUnpaid();
  }
  get version() {
    return isUrlUpdateVersion("paid-unpaid");
  }
}
