import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "\n      pt-0 bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full\n    " }
const _hoisted_2 = { class: "grid grid-cols-4 gap-6" }
const _hoisted_3 = {
  key: 2,
  class: "relative flex justify-center overflow-auto h-screen"
}
const _hoisted_4 = { class: "overflow-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!
  const _component_SelectSearch = _resolveComponent("SelectSearch")!
  const _component_TimeRangePicker = _resolveComponent("TimeRangePicker")!
  const _component_HeaderV2 = _resolveComponent("HeaderV2")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_WidgetDownload = _resolveComponent("WidgetDownload")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_HeaderV2, {
        labelButtonRight: "Download STT",
        isReset: "",
        onOnButtonRight: _ctx.onDownloadStt,
        onReset: _ctx.onResetFilter,
        onOnToggleShowFilter: _ctx.onToggleShowFilter
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_DataWrapper, { label: "Cari" }, {
              default: _withCtx(() => [
                _createVNode(_component_Search, {
                  onInput: _ctx.onSearch,
                  onClear: _ctx.clearSearch,
                  value: _ctx.searchValue,
                  placeholder: "Cari No. STT",
                  class: "mt-2 w-full"
                }, null, 8, ["onInput", "onClear", "value"])
              ]),
              _: 1
            }),
            _createVNode(_component_DataWrapper, { label: "Status Pembayaran" }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomDropdown, {
                  isExpand: _ctx.paymentStatusOpen,
                  "onUpdate:isExpand": _cache[1] || (_cache[1] = ($event: any) => (_ctx.paymentStatusOpen = $event)),
                  options: _ctx.paymentStatusOptions,
                  onSelect: _ctx.onSelectPaymentStatus,
                  placeholder: "Pilih status pembayaran",
                  class: "mt-2 w-full",
                  isCustomWidth: "",
                  selectedItem: _ctx.advancedFilterData.paymentStatus
                }, null, 8, ["isExpand", "options", "onSelect", "selectedItem"])
              ]),
              _: 1
            }),
            _createVNode(_component_DataWrapper, { label: "Status Proses" }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomDropdown, {
                  isExpand: _ctx.processStatusOpen,
                  "onUpdate:isExpand": _cache[2] || (_cache[2] = ($event: any) => (_ctx.processStatusOpen = $event)),
                  options: _ctx.processStatusOptions,
                  onSelect: _ctx.onSelectProcessStatus,
                  placeholder: "Pilih status proses",
                  class: "mt-2 w-full",
                  isCustomWidth: "",
                  selectedItem: _ctx.advancedFilterData.processStatus
                }, null, 8, ["isExpand", "options", "onSelect", "selectedItem"])
              ]),
              _: 1
            }),
            (_ctx.isInternal)
              ? (_openBlock(), _createBlock(_component_DataWrapper, {
                  key: 0,
                  label: "Kota Asal"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SelectSearch, {
                      onChevron: _cache[3] || (_cache[3] = e => (_ctx.isChevron = e)),
                      onFilter: _ctx.fetchDestination,
                      onFocus: _ctx.fetchDestination,
                      isLoading: _ctx.loadingDestination,
                      options: _ctx.destinationList,
                      keyName: "name",
                      keyValue: "code",
                      placeholder: "Pilih kota asal",
                      modelValue: _ctx.advancedFilterData.cityOrigin,
                      "onUpdate:modelValue": [
                        _cache[4] || (_cache[4] = ($event: any) => (_ctx.advancedFilterData.cityOrigin = $event)),
                        _ctx.onChangeOrigin
                      ],
                      class: "mt-2 w-full"
                    }, null, 8, ["onFilter", "onFocus", "isLoading", "options", "modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_DataWrapper, { label: "Kota Tujuan" }, {
              default: _withCtx(() => [
                _createVNode(_component_SelectSearch, {
                  onChevron: _cache[5] || (_cache[5] = e => (_ctx.isChevron = e)),
                  onFilter: _ctx.fetchDestination,
                  onFocus: _ctx.fetchDestination,
                  isLoading: _ctx.loadingDestination,
                  options: _ctx.destinationList,
                  keyName: "name",
                  keyValue: "code",
                  placeholder: "Pilih kota tujuan",
                  modelValue: _ctx.advancedFilterData.cityDestination,
                  "onUpdate:modelValue": [
                    _cache[6] || (_cache[6] = ($event: any) => (_ctx.advancedFilterData.cityDestination = $event)),
                    _ctx.onChangeDestination
                  ],
                  class: "mt-2 w-full"
                }, null, 8, ["onFilter", "onFocus", "isLoading", "options", "modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_DataWrapper, { label: "Tanggal Diubah" }, {
              default: _withCtx(() => [
                _createVNode(_component_TimeRangePicker, {
                  class: "mt-2 w-full",
                  startDate: _ctx.advancedFilterData.startDateRange,
                  endDate: _ctx.advancedFilterData.endDateRange,
                  onSelect: _ctx.setPeriode,
                  default: "7 hari terakhir",
                  isCloseDateAfterSelect: "",
                  ref: "dateRangeFilter"
                }, null, 8, ["startDate", "endDate", "onSelect"])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["onOnButtonRight", "onReset", "onOnToggleShowFilter"]),
      (_ctx.isError && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ErrorMessage, {
            key: 0,
            errorType: _ctx.errorCause,
            onTryAgain: _ctx.fetchSTTPaidUnpaid
          }, null, 8, ["errorType", "onTryAgain"]))
        : (!_ctx.STTPaidUnpaidData.length && _ctx.firstRequest && !_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_EmptyState, {
              key: 1,
              data: "data STT Paid/Unpaid"
            }))
          : (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode("div", _hoisted_4, [
                _createVNode(_component_TableV2, {
                  class: "my-2",
                  columns: _ctx.columns,
                  data: _ctx.STTPaidUnpaidData,
                  loading: _ctx.isLoading,
                  pagination: _ctx.pagination,
                  "onUpdate:pagination": _cache[7] || (_cache[7] = ($event: any) => (_ctx.pagination = $event)),
                  onRequest: _ctx.fetchSTTPaidUnpaid,
                  onTryAgain: _ctx.fetchSTTPaidUnpaid,
                  paginationStyle: "v3",
                  isPinnedScrollBottom: "",
                  borderTop: false,
                  borderBottom: false,
                  pinnedSubstractHeight: _ctx.isShowFilter ? '512px' : '260px',
                  paginationWidth: "w-3/4"
                }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "onTryAgain", "pinnedSubstractHeight"])
              ])
            ]))
    ]),
    _createVNode(_component_WidgetDownload)
  ], 64))
}