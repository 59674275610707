import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/app/ui/assets/svg/x-white.svg'


const _hoisted_1 = { class: "bg-red-lp-100 box-border flex flex-row absolute top-0 py-2 px-4 max-w-max items-center z-50 gap-2 border-solid border-2 rounded-full border-white" }
const _hoisted_2 = { class: "text-center font-montserrat font-14px font-normal not-italic text-white grow-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("span", _hoisted_2, " Ada " + _toDisplayString(_ctx.total) + " STT baru sudah lunas", 1),
    _createVNode("img", {
      src: _imports_0,
      class: "w-4 cursor-pointer",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onCloseTotalNotification && _ctx.onCloseTotalNotification(...args))),
      alt: "x"
    })
  ]))
}